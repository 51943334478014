import { Link } from "react-router-dom";
import "./ContactSection.css";
import instagram from "../../assets/images/instagram.svg";
import facebook from "../../assets/images/facebook.svg";

const ContactSection = () => {
  return (
    <div id="contact-background">
      <p id="contact-title">CONTACT</p>
      <p id="contact-description">
        If you have any questions or need further information, please feel free
        to contact us.
      </p>
      <div id="social-media-container">
        <a href="https://www.instagram.com/challenge_me_app/">
          <img src={instagram} width="50" />
        </a>
        <a href="https://www.facebook.com/app.ChallengeMe">
          <img src={facebook} width="50" />
        </a>
      </div>

      <Link id="contact-terms" to="/termsAndConditions">
        Terms and Conditions
      </Link>
    </div>
  );
};

export default ContactSection;
