import { useState } from "react";
import "./TeamSection.css";
import daniel from "../../assets/images/daniel.jpg";
import vlad from "../../assets/images/vlad.jpg";
import marius from "../../assets/images/marius.jpg";
import ReactCardFlip from "react-card-flip";
import TeamMemberBack from "../../components/TeamMemberBack/TeamMemberBack";

const TeamSection = () => {
  const [isFirstFlipped, setIsFirstFlipped] = useState(false);
  const [isSecondFlipped, setIsSecondFlipped] = useState(false);
  const [isThirdFlipped, setIsThirdFlipped] = useState(false);

  return (
    <div id="background-team">
      <p id="team-title">Meet the team</p>
      <div id="images-container">
        <ReactCardFlip isFlipped={isFirstFlipped}>
          <div onMouseEnter={() => setIsFirstFlipped(true)}>
            <img src={daniel} height="auto" id="profile-image"></img>
            <p id="name">Daniel Cocoș</p>
          </div>
          <TeamMemberBack
            onMouseLeave={() => setIsFirstFlipped(false)}
            instagramURL={"https://www.instagram.com/daniel.cocos/"}
            facebookURL={"https://www.facebook.com/daniel.cocos.1"}
            linkedinURL={"https://www.linkedin.com/in/daniel-cocos-418ab8133/"}
          />
        </ReactCardFlip>
        <ReactCardFlip isFlipped={isSecondFlipped}>
          <div onMouseEnter={() => setIsSecondFlipped(true)}>
            <img src={vlad} height="auto" id="profile-image"></img>
            <p id="name">Vlad Marușca</p>
          </div>
          <TeamMemberBack
            onMouseLeave={() => setIsSecondFlipped(false)}
            instagramURL={"https://www.instagram.com/vladmarusca/"}
            facebookURL={"https://www.facebook.com/marusca.vlad.7"}
            linkedinURL={"https://www.linkedin.com/in/vlad-marusca/"}
          />
        </ReactCardFlip>

        <ReactCardFlip isFlipped={isThirdFlipped}>
          <div onMouseEnter={() => setIsThirdFlipped(true)}>
            <img src={marius} height="auto" id="profile-image"></img>
            <p id="name">Marius Motoc</p>
          </div>
          <TeamMemberBack
            onMouseLeave={() => setIsThirdFlipped(false)}
            facebookURL={
              "https://www.facebook.com/profile.php?id=100008382968235"
            }
            linkedinURL={
              "https://www.linkedin.com/in/marius-constantin-motoc-624980174/"
            }
          />
        </ReactCardFlip>
      </div>
    </div>
  );
};

export default TeamSection;
