import "./TeamMemberBack.css";
import instagram from "../../assets/images/instagram.svg";
import facebook from "../../assets/images/facebook.svg";
import linkedin from "../../assets/images/linkedin.svg";

const TeamMemberBack = ({
  onMouseLeave,
  instagramURL,
  facebookURL,
  linkedinURL,
}) => {
  return (
    <div id="back-card" onMouseLeave={onMouseLeave}>
      {instagramURL && (
        <a href={instagramURL} target="_blank">
          <img src={instagram} width="35" height="auto"></img>
        </a>
      )}
      <a href={facebookURL} target="_blank">
        <img src={facebook} width="35" height="auto"></img>
      </a>
      <a href={linkedinURL} target="_blank">
        <img src={linkedin} width="35" height="auto"></img>
      </a>
    </div>
  );
};

export default TeamMemberBack;
