import "./Header.css";
import headerImage from "./headerImage.png";
import appStoreLogo from "./appStoreLogo.svg";
import googlePlay from "./googlePlay.svg";

const Header = () => {
  return (
    <div className="background">
      <div className="description-container">
        <p className="title">ChallengeMe</p>
        <h4 className="description">
          Escape the ordinary with fun challenges and rewarding prizes.
          ChallengeMe is an innovative free-to-use app that allows you to push
          your boundaries while accomplishing fun and unique tasks, according to
          the category selected.
        </h4>
        <div className="store-container">
          <a href="https://apps.apple.com/us/app/challengeme-your-venture/id1532641122">
            <img
              src={appStoreLogo}
              className="store-icon"
              alt="Get it on App store"
            ></img>
          </a>
          <a href="https://play.google.com/store/apps/details?id=ro.challengeme">
            <img
              className="store-icon"
              alt="Get it on Google Play"
              src={googlePlay}
            />
          </a>
        </div>
      </div>
      <img src={headerImage} className="image"></img>
    </div>
  );
};

export default Header;
