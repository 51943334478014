import "./App.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./assets/fonts/matiz_regular_macroman/stylesheet.css";
import ContactSection from "./sections/ContactSection/ContactSection";
import TeamSection from "./sections/TeamSection/TeamSection";
import CarouselSection from "./sections/CarouselSection/CarouselSection";
import Header from "./sections/HeaderSection/Header";
import { TermsAndConditions } from "./TermsAndConditions";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/termsAndConditions">
          <TermsAndConditions />
        </Route>
        <Route path="/">
          <div className="App">
            <Header />
            <CarouselSection />
            <TeamSection />
            <ContactSection />
          </div>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
