import "./Carousel.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import screen1 from "../../assets/images/screen1.png";
import screen2 from "../../assets/images/screen2.png";
import screen3 from "../../assets/images/screen3.png";
import screen4 from "../../assets/images/screen4.png";
import screen5 from "../../assets/images/screen5.png";
import screen6 from "../../assets/images/screen6.png";
import screen7 from "../../assets/images/screen7.png";

const CarouselSection = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1500 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1500, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 786, min: 0 },
      items: 1,
    },
  };

  return (
    <div id="background">
      <p id="title">What is ChallengeMe</p>
      <div id="separator"></div>
      <div id="description-container">
        <p id="description">
          The rules are simple - you download the app, select a category, choose
          a challenge, accomplish it, upload a picture for proof, and for each
          done task and its difficulty level you gain points and enter the race
          for multiple possible rewards.
        </p>
      </div>

      <div id="carousel-container">
        <Carousel
          responsive={responsive}
          swipeable={true}
          draggable={true}
          infinite={true}
          autoPlay
        >
          <img src={screen1} width="250" height="auto"></img>
          <img src={screen2} width="250" height="auto"></img>
          <img src={screen3} width="250" height="auto"></img>
          <img src={screen4} width="250" height="auto"></img>
          <img src={screen5} width="250" height="auto"></img>
          <img src={screen6} width="250" height="auto"></img>
          <img src={screen7} width="250" height="auto"></img>
        </Carousel>
      </div>
    </div>
  );
};

export default CarouselSection;
